<template>
  <BCard>
    <strong class="text-black font-semibold text-2xl">Rekening Bank</strong>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        v-if="isEmpty(dataRekening)"
        class="mt-2"
      >
        <BButton
          variant="outline-primary"
          class="w-full"
          :disabled="pinNotSet"
          @click="$router.push({ name: 'add-bank' })"
        >
          <BIconPlus />
          Tambah Rekaning
        </BButton>
        <div
          style="color: #C2C2C2"
          class="text-center my-2"
        >
          Kamu belum memiliki Rekening yang terdaftar. Silahkan tambahkan rekening untuk melakukan pernarikan saldo.
        </div>
      </div>
      <div
        v-else
        class="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] mt-2"
      >
        <BCard
          v-for="(data, idx) in dataRekening"
          :key="idx"
          class="cardRek"
        >
          <div class="flex justify-between items-center">
            <p class="text-black text-xl font-semibold m-0">
              Rekening {{ idx+1 }}
            </p>
            <!-- <img
              src="@/assets/images/svg/edit.svg"
              alt="Komerce"
              class="cursor-pointer"
              @click="handleEdit(data, idx)"
            > -->
          </div>
          <div class="my-1">
            <b>Nama Bank</b>
            <br>
            <span>{{ data.bank_name }}</span>
          </div>
          <div class="my-1">
            <b>Nomor Rekening</b>
            <br>
            <span>{{ data.bank_no }}</span>
          </div>
          <div class="my-1">
            <b>Nama</b>
            <br>
            <span>{{ data.bank_owner_name }}</span>
          </div>
        </BCard>
      </div>
      <BRow class="justify-end">
        <BButton
          v-if="!isEmpty(dataRekening)"
          :variant="dataRekening.length >= 6 ? 'outline-secondary' : 'outline-primary'"
          :disabled="dataRekening.length >= 6"
          class="mr-1"
          @click="$router.push({name: 'add-bank'})"
        >
          Tambah Rekening
        </BButton>
      </BRow>
    </BOverlay>

    <ModalPin @fromChild="getDataRekening" />
  </BCard>
</template>

<script>
import {
  BCard, BButton, BIconPlus, BOverlay,
} from 'bootstrap-vue'
import provider from '@/provider/provider'
import { BANK_ACCOUNT, SETTING_PIN } from '@/provider/url'
import { kompackAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import isEmpty from 'lodash/isEmpty'
import secureLocalStorage from '@/libs/secureLocalstorage'
import router from '@/router'
import ModalPin from './ModalPin.vue'

export default {
  components: {
    BCard,
    BButton,
    BIconPlus,
    BOverlay,
    ModalPin,
  },
  data() {
    return {
      loading: false,
      pinNotSet: true,
      dataRekening: [],
      isEmpty,
      userId: this?.$store?.state?.auth?.userData?.id,
      routeData: router.options.routes,
    }
  },
  mounted() {
    this.getDataCheckPin()
  },
  methods: {
    async getDataCheckPin() {
      await kompackAxiosIns
        .get(`${SETTING_PIN}?user_id=${this.userId}`)
        .then(res => {
          const { data } = res
          if (data.status === 'success') {
            this.pinNotSet = false
            this.$bvModal.show('modal-pin')
          } else {
            this.pinNotSet = true
            this.$router.push('/pin')
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertIconIcon',
                text: 'Gagal cek pin, silahkan refresh page',
                variant: 'danger',
              },
            }, { timeout: 2000 },
          )
        })
    },
    async getDataRekening() {
      this.loading = true
      await provider.single(BANK_ACCOUNT)
        .then(res => {
          const { data } = res
          this.dataRekening = data
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
          this.loading = false
        })
    },
    handleEdit(data, idx) {
      const { id } = data
      this.$router.push({ path: `/edit-bank/${id}/${idx + 1}` })
      secureLocalStorage.set('rekening', data)
    },
  },
}
</script>

<style scoped>
.cardRek {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  color: #222222;
}
</style>
