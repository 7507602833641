<template>
  <BModal
    id="modal-pin"
    ref="modal-pin"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    hide-header
    centered
  >
    <BCol class="flex justify-center mt-2">
      <h4 class="text-black font-semibold">
        Masukan PIN
      </h4>
    </BCol>

    <BCol class="flex text-center justify-center mt-1">
      <small>
        <strong>Mohon verifikasi identitas Kamu dengan memasukkan PIN.
          <br>
          <span
            style="color: #f95031"
            class="cursor-pointer"
            @click="$router.push({ name: 'pin' })"
          >
            Lupa PIN?
          </span>
        </strong>
      </small>
    </BCol>

    <BCol class="flex justify-center mt-2 mb-1">
      <CodeInput
        style="-webkit-text-security: disc"
        :loading="loading"
        class="input"
        @change="onChange"
      />
    </BCol>

    <BCol class="flex justify-center my-2">
      <BButton
        variant="outline-primary"
        class="mr-1"
        @click="closeModal"
      >
        Kembali
      </BButton>
      <BButton
        :variant="pin === null || pin.length < 6 ? 'secondary' : 'primary'"
        :disabled="pin === null || pin.length < 6"
        @click="onFinish"
      >
        <BSpinner
          v-if="loading"
          variant="light"
          small
        />
        Konfirmasi
      </BButton>
    </BCol>
  </BModal>
</template>

<script>
import {
  BModal, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import CodeInput from 'vue-verification-code-input'
import provider from '@/provider/provider'
import { CONFIRM_PIN } from '@/provider/url'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal, BCol, BButton, BSpinner, CodeInput,
  },
  data() {
    return {
      pin: '',
      userId: this?.$store?.state?.auth?.userData?.id,
      loading: false,
    }
  },
  methods: {
    async onFinish() {
      this.loading = true
      await provider.single(`${CONFIRM_PIN}?user_id=${this.userId}&pin=${this.pin}`)
        .then(async res => {
          const { data } = res
          if (data.is_match === true) {
            this.loading = false
            this.$bvModal.hide('modal-pin')
            this.$emit('fromChild')
          } else {
            this.loading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'PIN yang anda masukkan salah',
                variant: 'danger',
              },
            }, 2000)
            this.loading = false
          }
        })
    },
    onChange(value) {
      this.pin = value
    },
    closeModal() {
      this.$bvModal.hide('modal-pin')
      this.$router.push('/profile')
    },
  },
}
</script>

<style>

</style>
